import './dialogs.js';
import SignaturePad from 'signature_pad';

const prompts = {
  2021: [
    'Crystal',
    'Suit',
    'Vessel',
    'Knot',
    'Raven',
    'Spirit',
    'Fan',
    'Watch',
    'Pressure',
    'Pick',
    'Sour',
    'Stuck',
    'Roof',
    'Tick',
    'Helmet',
    'Compass',
    'Collide',
    'Moon',
    'Loop',
    'Sprout',
    'Fuzzy',
    'Open',
    'Leak',
    'Extinct',
    'Splat',
    'Connect',
    'Spark',
    'Crispy',
    'Patch',
    'Slither',
    'Risk'
  ],
  2020: [
    'Fish',
    'Wisp',
    'Bulky',
    'Radio',
    'Blade',
    'Rodent',
    'Fancy',
    'Teeth',
    'Throw',
    'Hope',
    'Disgusting',
    'Slippery',
    'Dune',
    'Armor',
    'Outpost',
    'Rocket',
    'Storm',
    'Trap',
    'Dizzy',
    'Coral',
    'Sleep',
    'Chef',
    'Rip',
    'Dig',
    'Buddy',
    'Hide',
    'Music',
    'Float',
    'Shoes',
    'Ominous',
    'Crawl'
  ],
  2019: [
    'Ring',
    'Mindless',
    'Bait',
    'Freeze',
    'Build',
    'Husky',
    'Enchanted',
    'Frail',
    'Swing',
    'Pattern',
    'Snow',
    'Dragon',
    'Ash',
    'Overgrown',
    'Legend',
    'Wild',
    'Ornament',
    'Misfit',
    'Sling',
    'Tread',
    'Treasure',
    'Ghost',
    'Ancient',
    'Dizzy',
    'Tasty',
    'Dark',
    'Coat',
    'Ride',
    'Injured',
    'Catch',
    'Ripe'
  ]
};

const promptText = document.querySelector('#prompt span');

var d = new Date();
var n = d.getDate() - 1;

promptText.textContent = prompts[2021][n];

var canvas = document.querySelector("canvas");
var signaturePad = new SignaturePad(canvas, {
  backgroundColor: 'rgb(255,255,255)'
});

function setCanvasSize() {
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  let data = signaturePad.toData();
  canvas.height = canvas.parentNode.offsetHeight * ratio;
  canvas.width = canvas.parentNode.offsetWidth * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  signaturePad.fromData(data);
}

window.addEventListener('resize', setCanvasSize)
setCanvasSize();

// Add functionality to UI buttons
document.getElementById('mirror').addEventListener('click', function () {
  document.body.classList.toggle('mirror');
});

document.getElementById('download').addEventListener('click', function (event) {
  this.href = signaturePad.toDataURL()
  this.setAttribute('download', `${encodeURI( promptText.textContent ).toLowerCase() }.png`)
});

document.getElementById('clear').addEventListener('click', function () {
  signaturePad.clear();
});

document.getElementById('undo').addEventListener('click', function () {
	var data = signaturePad.toData();
  if (data) {
    data.pop(); // remove the last dot or line
    signaturePad.fromData(data);
  }
});
